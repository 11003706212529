import request from '@/utils/request'

const url = '/'

export function generate(): Promise<string> {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}opening-code`,
      method: 'get',
      params: {
        openid: localStorage.getItem('Keep_OpenId')
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}
