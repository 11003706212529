













































import {Component, Vue} from 'vue-property-decorator'
import {generate} from '@/api/openingCode'
import OpenDoorLogApi from '@/api/doorOpeninglog'

@Component({})
export default class OpenCode extends Vue {
  private imgStr = ''
  private showLog = false
  private api = new OpenDoorLogApi()
  private tableData = []

  private async created() {
    await this.getCode()
  }

  private async handleLogList() {
    this.showLog = !this.showLog
    if (this.showLog) {
      const result = await this.api.getLogAsync({maxResultCount: 3})
      this.tableData = result.items
    }
  }

  get shipType() {
    return localStorage.getItem('Keep_ShipType')
  }

  private async getCode() {
    this.imgStr = await generate()
  }
}
