import request from '@/utils/request'

const url = '/keep-fit/doorOpeningLog'

export default class OpenDoorLogApi {
  public async getLogAsync(listQuery: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}/by-memberCode`,
        method: 'get',
        params: listQuery
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }
}
